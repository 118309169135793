/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #00ff00;
  --secondary-color: #333;
  --background-color: #000;
  --text-color: #fff;
  --font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  overflow-x: hidden;
}

.homepage {
  width: 100%;
  min-height: 100vh;
  position: relative;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

button {
  font-family: var(--font-family);
  cursor: pointer;
}

.section-padding {
  padding: 80px 0;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .section-padding {
    padding: 60px 0;
  }
}