/* src/styles/HomePage.css - Enhanced with mobile optimizations */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
  background-color: #000000;
  color: #ffffff;
  line-height: 1.5;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.homepage {
  width: 100%;
  overflow: hidden;
}

.section-divider {
  padding: 0 32px;
  max-width: 1440px;
  margin: 0 auto;
}

.divider-line {
  height: 1px;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(0, 240, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  margin: 80px 0;
}

/* Accessibility - reduce motion */
@media (prefers-reduced-motion) {
  * {
    animation: none !important;
    transition: none !important;
  }
  
  .scroll-indicator {
    animation: none !important;
  }
}

/* Mobile optimizations */
@media screen and (max-width: 992px) {
  .divider-line {
    margin: 60px 0;
  }
}

@media screen and (max-width: 768px) {
  .section-divider {
    padding: 0 20px;
  }
  
  .divider-line {
    margin: 50px 0;
  }
}

@media screen and (max-width: 480px) {
  .section-divider {
    padding: 0 16px;
  }
  
  .divider-line {
    margin: 40px 0;
  }
}