/* src/styles/Solutions.css */
.solutions-section {
  padding: 120px 0;
  background-color: #050505;
  position: relative;
}

.solutions-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(circle at 10% 20%, rgba(0, 240, 255, 0.03) 0%, transparent 25%),
    radial-gradient(circle at 90% 80%, rgba(0, 240, 255, 0.03) 0%, transparent 25%);
  z-index: 0;
}

.section-container {
  max-width: 1440px;
  padding: 0 32px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.section-header {
  text-align: center;
  margin-bottom: 80px;
}

.section-title {
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 24px;
  position: relative;
  display: inline-block;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #00f0ff, rgba(0, 240, 255, 0.3));
  border-radius: 3px;
}

.section-title .highlight {
  color: #00f0ff;
}

.section-description {
  max-width: 800px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.75);
}

.solutions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 32px;
  margin-bottom: 80px;
}

.solution-card {
  background: rgba(10, 10, 10, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 32px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.solution-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  border-color: rgba(0, 240, 255, 0.2);
  background: rgba(15, 15, 15, 0.7);
}

.solution-icon {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 12px;
}

.solution-title {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 16px;
}

.solution-description {
  font-size: 16px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 24px;
}

.solution-features {
  list-style: none;
  padding: 0;
  margin: 0 0 24px 0;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 12px;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.8);
}

.feature-item svg {
  flex-shrink: 0;
  margin-top: 3px;
}

.solution-action {
  margin-top: auto;
}

.learn-more {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #00f0ff;
  text-decoration: none;
  transition: all 0.2s ease;
}

.learn-more:hover {
  gap: 12px;
}

.section-cta {
  background: linear-gradient(90deg, rgba(0, 240, 255, 0.1), rgba(0, 150, 255, 0.05));
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 16px;
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.section-cta h3 {
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  max-width: 700px;
}

.cta-button {
  display: inline-block;
  background: linear-gradient(90deg, #00f0ff, #0080ff);
  color: #000000;
  font-weight: 600;
  padding: 14px 32px;
  border-radius: 6px;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 16px rgba(0, 240, 255, 0.3);
  border: none;
  cursor: pointer;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 240, 255, 0.4);
}

@media screen and (max-width: 992px) {
  .solutions-section {
    padding: 80px 0;
  }
  
  .section-title {
    font-size: 32px;
  }
  
  .section-description {
    font-size: 16px;
  }
  
  .solution-card {
    padding: 24px;
  }
}

@media screen and (max-width: 768px) {
  .solutions-section {
    padding: 60px 0;
  }
  
  .section-header {
    margin-bottom: 50px;
  }
  
  .solutions-grid {
    grid-template-columns: 1fr;
    gap: 24px;
    margin-bottom: 50px;
  }
  
  .section-cta {
    padding: 32px 24px;
  }
  
  .section-cta h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .solutions-section {
    padding: 50px 0;
  }
  
  .section-container {
    padding: 0 20px;
  }
  
  .section-title {
    font-size: 28px;
  }
  
  .solution-card {
    padding: 20px;
  }
  
  .solution-title {
    font-size: 22px;
  }
  
  .cta-button {
    width: 100%;
    padding: 12px 20px;
  }
}