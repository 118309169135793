/* src/styles/Hero.css - Fixed to prevent content from being cut off */
.hero-section {
  position: relative;
  width: 100%;
  min-height: 100vh; /* Changed from fixed height to minimum height */
  padding: 120px 0 100px; /* Added padding to ensure content isn't cut off */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -2;
  /* Ensure video plays smoothly even when looping */
  will-change: transform;
  transform: translateZ(0);
  /* Force hardware acceleration */
  -webkit-transform: translateZ(0);
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.85) 0%,
    rgba(0, 0, 0, 0.7) 50%,
    rgba(0, 0, 0, 0.6) 100%
  );
  z-index: -1;
}

.hero-container {
  width: 100%;
  max-width: 1440px;
  padding: 0 32px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.hero-tagline {
  font-size: 18px;
  font-weight: 500;
  color: #00f0ff;
  margin-bottom: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.hero-content {
  max-width: 650px;
  margin-bottom: 60px;
}

.hero-title {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 64px;
  line-height: 1.1;
  font-weight: 600;
  margin-bottom: 32px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title-line {
  display: block;
}

.title-line:last-child {
  color: #00f0ff; /* Neon blue */
}

.hero-subtitle {
  font-size: 20px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.85);
  margin-bottom: 40px;
  max-width: 540px;
}

.hero-actions {
  display: flex;
  gap: 16px;
}

.action-button {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 14px 28px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  outline: none;
  text-decoration: none;
}

.action-button.primary {
  background: linear-gradient(90deg, #00f0ff, #0080ff); /* Neon blue gradient */
  color: #000000;
  box-shadow: 0 4px 16px rgba(0, 240, 255, 0.3);
}

.action-button.primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 240, 255, 0.4);
}

.action-button.secondary {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.action-button.secondary:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.5);
}

.hero-stats {
  display: flex;
  align-items: center;
  gap: 16px;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 24px 32px;
  max-width: 600px;
  margin-bottom: 20px; /* Added margin to prevent overlap with scroll indicator */
}

.stat-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.stat-value {
  font-size: 36px;
  font-weight: 700;
  color: #00f0ff; /* Neon blue */
  line-height: 1;
}

.stat-label {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}

.stat-divider {
  width: 1px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
}

.scroll-indicator {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  opacity: 0.7;
  transition: opacity 0.2s ease;
  animation: bounce 2s infinite;
  text-decoration: none; 
}

.scroll-indicator:hover {
  opacity: 1;
}

.scroll-indicator span {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}

.scroll-indicator svg {
  width: 20px;
  height: 20px;
  stroke: #00f0ff; /* Neon blue */
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0) translateX(-50%);
  }
  40% {
    transform: translateY(-10px) translateX(-50%);
  }
  60% {
    transform: translateY(-5px) translateX(-50%);
  }
}

/* Fix for Safari to ensure seamless video looping */
@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    .hero-video {
      transform: translateZ(0);
    }
  }
}

@media screen and (max-width: 992px) {
  .hero-section {
    padding: 100px 0 80px; /* Adjusted padding for medium screens */
  }
  
  .hero-title {
    font-size: 48px;
  }
  
  .hero-subtitle {
    font-size: 18px;
  }
  
  .hero-stats {
    padding: 20px 24px;
  }
  
  .stat-value {
    font-size: 28px;
  }
}

@media screen and (max-width: 768px) {
  .hero-section {
    padding: 100px 0 70px; /* Further reduced padding for mobile */
  }
  
  .hero-tagline {
    font-size: 16px;
  }
  
  .hero-title {
    font-size: 36px;
  }
  
  .hero-subtitle {
    font-size: 16px;
  }
  
  .hero-actions {
    flex-direction: column;
    gap: 12px;
    width: 100%; /* Full width on mobile */
    max-width: 100%;
  }
  
  .action-button {
    width: 100%; /* Full width buttons on mobile */
    justify-content: center;
  }
  
  .hero-stats {
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    width: 100%; /* Full width on mobile */
    max-width: 100%;
    margin-bottom: 60px; /* Increased bottom margin on mobile */
  }
  
  .stat-divider {
    width: 100%;
    height: 1px;
  }
  
  .scroll-indicator {
    bottom: 20px; /* Moved up on mobile */
  }
}

@media screen and (max-height: 700px) {
  .hero-section {
    padding: 120px 0 60px; /* Adjusted for shorter heights */
  }
  
  .hero-content {
    margin-bottom: 30px;
  }
  
  .hero-title {
    font-size: 42px;
    margin-bottom: 20px;
  }
  
  .hero-subtitle {
    margin-bottom: 25px;
  }
  
  .hero-stats {
    padding: 15px 20px;
    margin-bottom: 50px;
  }
}