/* src/styles/Navbar.css */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease;
  background-color: rgba(0, 0, 0, 0.7);
  padding: px 0;
}

.navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  padding: 16px 0;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 32px;
}

.navbar-logo {
  display: flex;
  align-items: center;
  gap: 0px;
}

.logo-image {
  height: 75px;
  width: auto;
margin-top: 10px;
  filter: drop-shadow(0 0 8px rgba(0, 240, 255, 0.4));
}

.logo-text {
  display: flex;
  flex-direction: column;
  line-height: 1;
}

.company-name {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 0px;
}

.company-domain {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: #00f0ff;
  letter-spacing: 0px;
}

.navbar-menu {
  display: flex;
  justify-content: center;
}

.menu-items {
  display: flex;
  list-style: none;
  gap: 32px;
  margin: 0;
  padding: 0;
}

.menu-items li a {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.85);
  text-decoration: none;
  transition: all 0.2s ease;
  padding: 6px 0;
  position: relative;
}
/* Add these styles to your existing Navbar.css file */

/* Auth button styles */
.auth-button {
  background: transparent;
  border: 1px solid #3a86ff;
  color: #3a86ff;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 15px;
}

.auth-button:hover {
  background: rgba(58, 134, 255, 0.1);
}

.logout-button {
  border-color: #ff3a5e;
  color: #ff3a5e;
}

.logout-button:hover {
  background: rgba(255, 58, 94, 0.1);
}

/* User menu styles */
.user-menu {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.user-email {
  font-size: 14px;
  margin-right: 10px;
  color: #333;
}

/* Login modal styles */
.login-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.login-form-container {
  background: white;
  border-radius: 8px;
  padding: 30px;
  width: 100%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.login-form-container h2 {
  margin-bottom: 20px;
  color: #222;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #444;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.login-submit-button {
  width: 100%;
  padding: 12px;
  background: #3a86ff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s ease;
}

.login-submit-button:hover {
  background: #2a76ef;
}

.error-message {
  color: #ff3a5e;
  margin-bottom: 15px;
  text-align: center;
}

.login-footer {
  margin-top: 20px;
  text-align: center;
}

.login-footer a {
  color: #3a86ff;
  text-decoration: none;
}

.login-footer a:hover {
  text-decoration: underline;
}

/* Responsive styles */
@media (max-width: 768px) {
  .user-menu {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .user-email {
    margin-bottom: 10px;
  }
  
  .navbar-actions {
    flex-wrap: wrap;
  }
  
  .auth-button {
    margin-bottom: 10px;
  }
}
.menu-items li a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #00f0ff;
  transition: width 0.3s ease;
}

.menu-items li a:hover {
  color: #ffffff;
}

.menu-items li a:hover::after {
  width: 100%;
}

.cta-button {
  background: linear-gradient(90deg, #00f0ff, #0080ff);
  color: #000000 !important;
  padding: 10px 20px !important;
  border-radius: 4px;
  font-weight: 600 !important;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 12px rgba(0, 240, 255, 0.3);
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 240, 255, 0.4) !important;
}

.cta-button::after {
  display: none !important;
}

.navbar-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.action-button {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.action-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.5);
}

.mobile-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 18px;
  cursor: pointer;
}

.mobile-toggle span {
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  transition: all 0.3s ease;
  border-radius: 1px;
}

@media screen and (max-width: 1024px) {
  .navbar-menu {
    position: fixed;
    top: 80px;
    left: -100%;
    width: 100%;
    height: calc(100vh - 80px);
    background-color: rgba(0, 0, 0, 0.95);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    transition: all 0.3s ease;
    z-index: 100;
  }
  
  .navbar-menu.active {
    left: 0;
  }
  
  .menu-items {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 32px;
    padding-bottom: 100px;
  }
  
  .menu-items li a {
    font-size: 18px;
  }
  
  .mobile-toggle {
    display: flex;
  }
  
  .mobile-toggle.active span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  
  .mobile-toggle.active span:nth-child(2) {
    opacity: 0;
  }
  
  .mobile-toggle.active span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}