/* src/styles/Consultation.css */
.consultation-section {
    position: relative;
    width: 100%;
    padding: 120px 0;
    background-color: #000000;
    overflow: hidden;
  }
  
  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle at 70% 40%,
      rgba(0, 240, 255, 0.1),
      rgba(0, 0, 0, 0.9) 60%
    );
    z-index: 0;
  }
  
  .consultation-container {
    width: 100%;
    max-width: 1440px;
    padding: 0 32px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }
  
  .consultation-heading {
    text-align: center;
    margin-bottom: 60px;
  }
  
  .section-title {
    font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 42px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 16px;
    position: relative;
    display: inline-block;
  }
  
  .section-title:after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #00f0ff, #0080ff);
    border-radius: 1.5px;
  }
  
  .section-subtitle {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
    max-width: 720px;
    margin: 0 auto;
    line-height: 1.6;
  }
  
  .consultation-content {
    display: flex;
    gap: 48px;
    margin-top: 40px;
  }
  
  .consultation-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .info-card {
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 28px;
    transition: all 0.3s ease;
  }
  
  .info-card:hover {
    transform: translateY(-5px);
    border-color: rgba(0, 240, 255, 0.3);
    box-shadow: 0 8px 24px rgba(0, 240, 255, 0.1);
  }
  
  .info-icon {
    width: 48px;
    height: 48px;
    background: rgba(0, 240, 255, 0.15);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }
  
  .info-icon svg {
    stroke: #00f0ff;
  }
  
  .info-card h3 {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 12px;
  }
  
  .info-card p {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
    line-height: 1.6;
  }
  
  .consultation-form-container {
    flex: 1.5;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    padding: 40px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  }
  
  .consultation-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .form-row {
    display: flex;
    gap: 20px;
  }
  
  .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .form-group label {
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    padding: 12px 16px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: #ffffff;
    font-size: 16px;
    transition: all 0.3s ease;
  }
  
  .form-group input:focus,
  .form-group select:focus,
  .form-group textarea:focus {
    outline: none;
    border-color: rgba(0, 240, 255, 0.5);
    box-shadow: 0 0 0 2px rgba(0, 240, 255, 0.2);
  }
  
  .form-group input::placeholder,
  .form-group textarea::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
  
  .form-group select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 16px center;
    padding-right: 40px;
  }
  
  .form-footer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 12px;
  }
  
  .form-disclaimer {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
  }
  
  .submit-button {
    background: linear-gradient(90deg, #00f0ff, #0080ff);
    color: #000000;
    border: none;
    border-radius: 8px;
    padding: 14px 28px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 16px rgba(0, 240, 255, 0.3);
    align-self: flex-end;
  }
  
  .submit-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 240, 255, 0.4);
  }
  
  .submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
  }
  
  .form-error {
    background-color: rgba(255, 62, 62, 0.1);
    border: 1px solid rgba(255, 62, 62, 0.3);
    color: #ff5e5e;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .success-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    min-height: 400px;
    padding: 20px;
  }
  
  .success-icon {
    margin-bottom: 24px;
  }
  
  .success-icon svg {
    stroke: #00f0ff;
  }
  
  .success-message h3 {
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 16px;
  }
  
  .success-message p {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
    max-width: 450px;
    margin-bottom: 32px;
    line-height: 1.6;
  }
  
  .back-button {
    background: transparent;
    border: 1px solid rgba(0, 240, 255, 0.5);
    color: #00f0ff;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .back-button:hover {
    background: rgba(0, 240, 255, 0.1);
    border-color: rgba(0, 240, 255, 0.7);
  }
  
  /* Responsive styles */
  @media screen and (max-width: 992px) {
    .consultation-section {
      padding: 100px 0;
    }
    
    .section-title {
      font-size: 36px;
    }
    
    .section-subtitle {
      font-size: 17px;
    }
    
    .consultation-content {
      flex-direction: column;
      gap: 40px;
    }
    
    .consultation-info {
      flex-direction: row;
      flex-wrap: wrap;
    }
    
    .info-card {
      flex: 1;
      min-width: 290px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .consultation-section {
      padding: 80px 0;
    }
    
    .consultation-container {
      padding: 0 20px;
    }
    
    .section-title {
      font-size: 32px;
    }
    
    .section-subtitle {
      font-size: 16px;
    }
    
    .consultation-heading {
      margin-bottom: 40px;
    }
    
    .consultation-info {
      flex-direction: column;
    }
    
    .consultation-form-container {
      padding: 30px 20px;
    }
    
    .form-row {
      flex-direction: column;
      gap: 24px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .section-title {
      font-size: 28px;
    }
    
    .consultation-form-container {
      padding: 24px 16px;
    }
    
    .info-card {
      padding: 20px;
    }
    
    .submit-button {
      width: 100%;
    }
  }