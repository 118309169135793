/* src/styles/Clients.css */
.clients-section {
    padding: 120px 0;
    background-color: #050505;
    position: relative;
  }
  
  .section-container {
    max-width: 1440px;
    padding: 0 32px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }
  
  .section-header {
    text-align: center;
    margin-bottom: 60px;
  }
  
  .section-title {
    font-size: 40px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 24px;
    position: relative;
    display: inline-block;
  }
  
  .section-title::after {
    content: '';
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #6dc7f4, rgba(38, 100, 206, 0.3));
    border-radius: 3px;
  }
  

  
  .section-description {
    max-width: 800px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.75);
  }
  
  .client-logos {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    margin-bottom: 80px;
    align-items: center;
  }
  
  .logo-placeholder {
    height: 80px;
    background: rgba(20, 20, 20, 0.6);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: grayscale(100%);
    opacity: 0.7;
    transition: all 0.3s ease;
  }
  
  .logo-placeholder:hover {
    filter: grayscale(0%);
    opacity: 1;
    border-color: rgba(0, 255, 0, 0.3);
    transform: translateY(-5px);
  }
  
  .logo-placeholder span {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
  }
  
  .testimonials-container {
    margin-bottom: 80px;
    overflow: hidden;
    position: relative;
  }
  
  .testimonials-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
  
  .testimonial-card {
    background: rgba(20, 20, 20, 0.6);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 32px;
    transition: all 0.3s ease;
    height: 100%;
  }
  
  .testimonial-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
    border-color: rgba(0, 255, 0, 0.2);
  }
  
  .quote-mark {
    margin-bottom: 16px;
    opacity: 0.4;
  }
  
  .testimonial-text {
    font-size: 16px;
    line-height: 1.7;
    color: rgba(255, 255, 255, 0.85);
    margin-bottom: 24px;
    font-style: italic;
  }
  
  .testimonial-author {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .author-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .avatar-placeholder {
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #00ff00, #009900);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-weight: 600;
    font-size: 20px;
  }
  
  .author-info {
    display: flex;
    flex-direction: column;
  }
  
  .author-name {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    margin: 0 0 4px 0;
  }
  
  .author-title, .author-company {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
  }
  
  .case-studies-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .case-studies-header h3 {
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 16px;
  }
  
  .case-studies-header p {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.75);
    max-width: 600px;
    margin: 0 auto;
  }
  
  .case-studies-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 30px;
  }
  
  .case-study-card {
    background: rgba(20, 20, 20, 0.6);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .case-study-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
    border-color: rgba(0, 255, 0, 0.2);
  }
  
  .case-study-visual {
    height: 180px;
    overflow: hidden;
  }
  
  .case-study-placeholder {
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(0, 255, 0, 0.1), rgba(0, 60, 0, 0.3));
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 18px;
  }
  
  .case-study-content {
    padding: 24px;
  }
  
  .case-study-industry {
    display: inline-block;
    font-size: 14px;
    color: #00ff00;
    margin-bottom: 12px;
    font-weight: 500;
  }
  
  .case-study-title {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    margin: 0 0 16px 0;
  }
  
  .case-study-results {
    margin-bottom: 24px;
  }
  
  .case-study-results h5 {
    font-size: 16px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.9);
    margin: 0 0 12px 0;
  }
  
  .results-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .result-item {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 8px;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .result-item svg {
    flex-shrink: 0;
    margin-top: 3px;
  }
  
  .case-study-link {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 15px;
    font-weight: 500;
    color: #00ff00;
    text-decoration: none;
    transition: all 0.2s ease;
  }
  
  .case-study-link:hover {
    gap: 12px;
  }
  
  @media screen and (max-width: 1200px) {
    .client-logos {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media screen and (max-width: 992px) {
    .testimonials-wrapper {
      grid-template-columns: 1fr;
      gap: 24px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .client-logos {
      grid-template-columns: repeat(2, 1fr);
    }
    
    .case-studies-grid {
      grid-template-columns: 1fr;
    }
  }