/* src/components/Contact.css */
.contact-section {
    background-color: #0a0a0a;
    padding: 100px 0;
  }
  
  .contact-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 4rem;
    margin-top: 3rem;
  }
  
  .contact-info {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .info-item h3 {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: var(--primary-color);
  }
  
  .info-item p {
    font-size: 1rem;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .contact-form {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    border-radius: 12px;
    padding: 2.5rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-group label {
    display: block;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 0.8rem;
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    color: white;
    font-family: var(--font-family);
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(0, 255, 0, 0.1);
  }
  
  .submit-btn {
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    padding: 0.8rem 2rem;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 1rem;
  }
  
  .submit-btn:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(0, 255, 0, 0.5);
  }
  
  @media screen and (max-width: 992px) {
    .contact-container {
      grid-template-columns: 1fr;
      gap: 3rem;
    }
    
    .contact-info {
      order: 2;
    }
  }
  
  @media screen and (max-width: 576px) {
    .contact-form {
      padding: 1.5rem;
    }
  }