/* src/styles/Expertise.css */
.expertise-section {
  padding: 120px 0;
  background-color: #080808;
  position: relative;
}

.expertise-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(to right, rgba(0, 240, 255, 0.02) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(0, 240, 255, 0.02) 1px, transparent 1px);
  background-size: 40px 40px;
  z-index: 0;
}

.section-container {
  max-width: 1440px;
  padding: 0 32px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.section-header {
  text-align: center;
  margin-bottom: 80px;
}

.section-title {
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 24px;
  position: relative;
  display: inline-block;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #00f0ff, rgba(0, 240, 255, 0.3));
  border-radius: 3px;
}

.section-title .highlight {
  color: #00f0ff;
}

.section-description {
  max-width: 800px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.75);
}

.expertise-tabs {
  margin-top: 60px;
}

.tabs-navigation {
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 40px;
  overflow-x: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.tabs-navigation::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.tab-button {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 24px;
  background: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.tab-button:hover {
  color: rgba(255, 255, 255, 0.9);
}

.tab-button.active {
  color: #00f0ff;
  border-bottom-color: #00f0ff;
}

.tab-icon {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.expertise-content {
  display: grid;
  grid-template-columns: 5fr 4fr;
  gap: 60px;
  align-items: center;
}

.expertise-info {
  padding-right: 20px;
}

.expertise-title {
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 20px;
}

.expertise-description {
  font-size: 18px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 40px;
}

.expertise-capabilities {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
  margin-bottom: 40px;
}

.capability-item {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 24px;
  transition: all 0.2s ease;
}

.capability-item:hover {
  transform: translateY(-5px);
  border-color: rgba(0, 240, 255, 0.2);
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 10px 20px rgba(0, 240, 255, 0.05);
}

.capability-title {
  font-size: 18px;
  font-weight: 600;
  color: #00f0ff;
  margin-bottom: 12px;
}

.capability-description {
  font-size: 15px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.7);
}

.expertise-cta {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #00f0ff;
  text-decoration: none;
  transition: all 0.2s ease;
  margin-top: 20px;
}

.expertise-cta:hover {
  gap: 12px;
}

.expertise-visual {
  position: relative;
  height: 100%;
}

.visual-placeholder {
  background: linear-gradient(135deg, rgba(0, 240, 255, 0.1), rgba(0, 240, 255, 0.02));
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 12px;
  aspect-ratio: 4/3;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2), inset 0 0 20px rgba(0, 240, 255, 0.05);
}

.placeholder-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.placeholder-icon {
  font-size: 48px;
}

.placeholder-text {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
}

/* Animate tab transitions */
.tabs-content {
  transition: opacity 0.3s ease;
}

/* Add subtle animation to icons */
.visual-placeholder svg {
  animation: pulse 4s infinite ease-in-out;
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.8;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
}

@media screen and (max-width: 992px) {
  .expertise-section {
    padding: 80px 0;
  }
  
  .expertise-content {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  
  .expertise-info {
    padding-right: 0;
  }
  
  .tab-button {
    padding: 12px 16px;
    font-size: 14px;
  }
  
  .tab-icon {
    font-size: 18px;
  }
  
  .section-title {
    font-size: 32px;
  }
  
  .expertise-title {
    font-size: 28px;
  }
}

@media screen and (max-width: 768px) {
  .expertise-section {
    padding: 60px 0;
  }
  
  .expertise-capabilities {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  
  .capability-item {
    padding: 20px;
  }
  
  .section-header {
    margin-bottom: 50px;
  }
  
  .expertise-description {
    font-size: 16px;
    margin-bottom: 30px;
  }
  
  .tabs-navigation {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 480px) {
  .expertise-section {
    padding: 50px 0;
  }
  
  .section-container {
    padding: 0 20px;
  }
  
  .tab-button {
    padding: 10px 12px;
    font-size: 13px;
  }
  
  .capability-title {
    font-size: 16px;
  }
  
  .capability-description {
    font-size: 14px;
  }
}