/* src/components/Footer.css */
.footer {
    background-color: #050505;
    padding: 70px 0 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
  }
  
  .footer-content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 4rem;
    margin-bottom: 3rem;
  }
  
  .footer-logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .footer-logo-img {
    height: 60px;
    width: auto;
    margin-bottom: 1rem;
  }
  
  .footer-logo-text {
    font-size: 1.5rem;
    font-weight: 300;
    color: white;
    margin-bottom: 1rem;
  }
  
  .footer-links {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
  
  .footer-links-column h4 {
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--primary-color);
    margin-bottom: 1.5rem;
  }
  
  .footer-links-column ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-links-column li {
    margin-bottom: 0.8rem;
  }
  
  .footer-links-column a {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.2s ease;
  }
  
  .footer-links-column a:hover {
    color: var(--primary-color);
  }
  
  .footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .social-icons {
    display: flex;
    gap: 1.5rem;
  }
  
  .social-icon {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.9rem;
    transition: all 0.2s ease;
  }
  
  .social-icon:hover {
    color: var(--primary-color);
  }
  
  .copyright p {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.5);
  }
  
  @media screen and (max-width: 992px) {
    .footer-content {
      grid-template-columns: 1fr;
      gap: 3rem;
    }
    
    .footer-logo {
      align-items: center;
      text-align: center;
    }
  }
  
  @media screen and (max-width: 768px) {
    .footer-links {
      grid-template-columns: 1fr;
      gap: 2rem;
      text-align: center;
    }
    
    .footer-bottom {
      flex-direction: column;
      text-align: center;
    }
  }